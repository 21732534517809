import React from "react";
const PartnerHome = React.lazy(() => import("./Home/Index.js"));
const PartnerSales = React.lazy(() => import("./Sales/Index.js"));
const PartnerSettings = React.lazy(() => import("./Settings/Index.js"));
const WithDrawHistory = React.lazy(() => import("./WithdrawHistory/Index.js"));

export let partner_routes = [
  {
    path: "/partner",
    element: <PartnerHome />,
  },
  {
    path: "/partner/sprzedaz",
    element: <PartnerSales />,
  },
  {
    path: "/partner/wyplaty",
    element: <WithDrawHistory />,
  },
  {
    path: "/partner/ustawienia",
    element: <PartnerSettings />,
  },
];
