import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";
import { user_routes } from "./pages/User/Index.js";
import { accounts_routes } from "./pages/Accounts/Index.js";
import { admin_routes } from "./pages/Admin/Index.js";
import { core_routes } from "./pages/Core/Index.js";
import { partner_routes } from "./pages/Partner/Index.js";

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {authCtx.isLoggedIn && (
            <>
              {user_routes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
              {partner_routes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
              {admin_routes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </>
          )}
          {/* ACCOUNTS */}
          {accounts_routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          {/* CORE */}
          {core_routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}

          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
