import React from "react";
const UserHome = React.lazy(() => import("./Home/Index.js"));
const UserBillings = React.lazy(() => import("./Billings/Index.js"));
const UserDelivery = React.lazy(() => import("./Delivery/Index.js"));
const UserOrders = React.lazy(() => import("./Orders/Index.js"));
const UserSettings = React.lazy(() => import("./Settings/Index.js"));
const UserWishlist = React.lazy(() => import("./Wishlist/Index.js"));
const UserMysteryBoxes = React.lazy(() => import("./MysteryBoxes/Index.js"));
const UserSpinner = React.lazy(() => import("./Spinner/Index.js"));
const UserMysteryBoxesWin = React.lazy(() =>
  import("./MysteryBoxWin/Index.js")
);

export let user_routes = [
  {
    path: "/panel",
    element: <UserHome />,
  },
  {
    path: "/panel/platnosci",
    element: <UserBillings />,
  },
  {
    path: "/panel/dostawa",
    element: <UserDelivery />,
  },
  {
    path: "/panel/zamowienia",
    element: <UserOrders />,
  },
  {
    path: "/panel/ustawienia",
    element: <UserSettings />,
  },
  {
    path: "/panel/wishlista",
    element: <UserWishlist />,
  },
  {
    path: "/panel/dankycase",
    element: <UserMysteryBoxes />,
  },
  {
    path: "/panel/spinner/:userroulette_id",
    element: <UserSpinner />,
  },
  {
    path: "/panel/dankycase/wygrane",
    element: <UserMysteryBoxesWin />,
  },
];
