import React from "react";
const Login = React.lazy(() => import("./Login/Index.js"));
const Password = React.lazy(() => import("./Password/Index.js"));
const Register = React.lazy(() => import("./Register/Index.js"));

export let accounts_routes = [
  {
    path: "/zaloguj-sie",
    element: <Login />,
  },
  {
    path: "/zarejestruj-sie",
    element: <Register />,
  },
  {
    path: "/przypomnij-haslo",
    element: <Password />,
  },
];
